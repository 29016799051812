import React,{useState,useEffect,Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import Button from '../UI/Button';
import classes from './Turnover.module.css';
import axios from 'axios';

const Turnover = (props) =>{
	const navigate = useNavigate();
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [manufacturers, setManufacturers]=useState([]);
	const [manufacturerSelection, setManufacturerSelection] = useState("all");
	const [manufacturerDropdown, setManufacturerDropdown] = useState('');
	const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0, 10));
	const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
	const [stats, setStats] = useState();
	const [pieData, setPieData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);


const COLORS = [
  '#0088FE', '#FF8042', '#00C49F', '#FFBB28', '#A833B9', '#E57373', '#4CAF50', '#FFC107', '#673AB7', '#FF5722',
  '#1E88E5', '#D81B60', '#43A047', '#FB8C00', '#8E24AA', '#E53935', '#3949AB', '#00ACC1', '#FDD835', '#6D4C41',
  '#EC407A', '#7CB342', '#FF9800', '#3F51B5', '#C2185B', '#009688', '#FBC02D', '#5D4037', '#8BC34A', '#F44336',
  '#9C27B0', '#CDDC39', '#FFEB3B', '#E91E63', '#2196F3', '#FF5252', '#4DB6AC', '#BDBDBD', '#03A9F4', '#FF4081',
  '#00BCD4', '#9E9E9E', '#4CAF50', '#B2FF59', '#B71C1C', '#64FFDA', '#FF6F00', '#311B92', '#F50057', '#76FF03'
];
const manufacturerGroups = [
  ['Nef-nef', 'Nima home', 'Guy Laroche', 'Borea', 'Cokitex', 'Das Home', 'Teoran', 'Kocoon', 'LLH', 'Liolios Home', 'Greenwich Polo club', 'Saint Clair', 'Moda sofa', 'MODA SOFA - ΔΗΜΗΤΡΑΚOΠΟΥΛΟΣ', 'Dream', 'Melinen', 'Feel & Touch', 'Dim', 'Raison d\'etre', 'BNK', 'Gofis home', 'Kentia', 'ASTRON Italy', 'MK', 'SUNSHINE HOME', 'BENETTON', 'sunny', 'Vasbros', 'Teoran', 'VESTA', 'Anna Riska', 'HOTEL', 'evina'],
  ['Simi', 'ΒΑΣΙΛΕΙΑΔΗΣ', 'Μυλωνάς', 'Thema', 'Σαρλάς', 'TrendyTex', 'Attica', 'INS', 'Astir'],
  ['Anartisi', 'Homestyle - Skiastron', 'HS', 'Import Hellas'],
  ['La Luna', 'Douceur', 'Magniflex'],
  ['Royal Carpet', 'NewPlan', 'Eurofil', 'Βιοκαρπέτ', 'Chios Hellas', 'Alexander Carpets', 'Atlantis', 'Sakalidis'],
  ['ΔΙΚΟ ΜΑΣ - ΜΑΓΑΖΙ', 'E-interior', 'Μεταποίηση-Ραφείο', 'Aloof', 'The Happy Pirate', 'Κέντημα'],
];

const pieChartNames = [
  "Λευκά είδη",
  "Υφάσματα-Ρόμαν",
  "Κουρτινόξυλα-Ρόλερ",
  "Ύπνος-Στρώματα",
  "Χαλιά-μοκέτες",
  "Δικά μας-Μεταποίηση"
];
const getEverythingElseGroup = () => {
  // Flatten all manufacturers from the first six groups
  const allManufacturersInGroups = manufacturerGroups.slice(0, 6).flat();

   const filteredData = pieData.filter(entry =>
    !allManufacturersInGroups.some(manufacturer =>
      manufacturer.toUpperCase() === entry.name.toUpperCase()
    )
  );

  // Calculate total sum of values in "Everything Else"
  const total = filteredData.reduce((acc, entry) => acc + entry.value, 0);

  // Add percentage field
  return filteredData.map(entry => ({
    ...entry,
    percent: total > 0 ? entry.value / total : 0, // Avoid division by zero
  }));
};


const filteredDataForPie = (manufacturerList) => {
  // Filter the pieData based on the manufacturerList
  const filteredData = pieData.filter(entry =>
    manufacturerList.some(
      manufacturer => manufacturer.toUpperCase() === entry.name.toUpperCase()
    )
  );

  // Calculate the total sum of values in the filtered data
  const total = filteredData.reduce((acc, entry) => acc + entry.value, 0);

  // Map the filtered data and add the percentage based on the filtered total
  const dataWithPercent = filteredData.map(entry => ({
    ...entry,
    percent: total > 0 ? entry.value / total : 0, // Avoid division by zero
  }));

  return dataWithPercent;
};

const aggregatePiesForComparison = (filteredPies) => {
  const totalAggregated = filteredPies.reduce(
    (sum, pieData) => sum + pieData.reduce((subtotal, entry) => subtotal + entry.value, 0),
    0
  );

  // Aggregate the pies into one comparison dataset
  return filteredPies.map((pieData, index) => {
    const value = pieData.reduce((sum, entry) => sum + entry.value, 0);
    return {
      name: pieChartNames[index], // Give each group a name, adjust as needed
      value,
      percent: totalAggregated > 0 ? value / totalAggregated : 0, // Avoid division by zero
    };
  });
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderTooltip = ({ active, payload, label, percent }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;  // Get data for the current slice
    const percent = data.percent;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
        <p className="label">{`${data.name} - ${data.value.toFixed(2)}€ - ${(percent * 100).toFixed(2)}%`}</p>
      </div>
    );
  }

  return null;
};

const renderMultiplePieCharts = () => {
    return manufacturerGroups.map((group, index) => {
	    const filteredPieData = filteredDataForPie(group);

	    // Check if there is any data for this pie chart, and render only if there is data
	    if (filteredPieData.length === 0) {
	      return null; // Skip rendering this pie chart if there is no data
	    }

	    return (
	      <div key={index} className={classes.pieChartItem} style={{ marginBottom: '40px' }}>
	        <h3>{pieChartNames[index]}</h3>
	        <ResponsiveContainer width="100%" height={400}>
	          <PieChart>
	            <Pie
	              dataKey="value"
	              data={filteredPieData}
	              cx="50%"
	              cy="50%"
	              outerRadius={150}
	              labelLine={false}
	              label={renderCustomizedLabel}
	            >
	              {filteredPieData.map((entry, idx) => (
	                <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
	              ))}
	            </Pie>
	            <Tooltip content={renderTooltip}/>
	          </PieChart>
	        </ResponsiveContainer>
	      </div>
	    );
	  });
  };

const renderEverythingElsePieChart = () => {
  const everythingElseData = getEverythingElseGroup(); // Get the data for the "everything else" group
  if (everythingElseData.length === 0) {
    return null; // Skip rendering this pie chart if there is no data
  }
  return (
    <div key="everythingElse" className={classes.pieChartItem} style={{ marginBottom: '40px' }}>
      <h3>Διάφορα</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            dataKey="value"
            data={everythingElseData}
            cx="50%"
            cy="50%"
            outerRadius={150}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {everythingElseData.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={renderTooltip}/>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const renderComparisonPieChart = () => {
    const filteredPies = manufacturerGroups
    .map((group) => filteredDataForPie(group)) // Filter data for each group
    .filter((filteredPieData) => filteredPieData.length > 0); // Remove empty pies

  const aggregatedPieData = aggregatePiesForComparison(filteredPies);

	    return (
	      <div key="comparisonPie" className={classes.pieChartItem} style={{ marginBottom: '40px' }}>
	        <h3>Σύνολο</h3>
	        <ResponsiveContainer width="100%" height={400}>
	          <PieChart>
	            <Pie
	              dataKey="value"
	              data={aggregatedPieData}
	              cx="50%"
	              cy="50%"
	              outerRadius={150}
	              labelLine={false}
	              label={renderCustomizedLabel}
	            >
	              {aggregatedPieData.map((entry, idx) => (
	                <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
	              ))}
	            </Pie>
	            <Tooltip content={renderTooltip}/>
	          </PieChart>
	        </ResponsiveContainer>
	      </div>
	    );
  };

useEffect(() => {
    setIsLoaded(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    if (response.data.message!=="Success"){
    	removeCookies('Name', { path: '/' });
    	removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
  	removeCookies('Name', { path: '/' });
  	removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);

	useEffect(() => {
    	
    	let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.orders.e-interior.gr/api/tziros/manufacturer-list',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+cookies.Token, 
		  }}
	  	axios.request(config)
			.then((response) => {
				setManufacturers(response.data)
				console.log(response.data);
			})
			.catch((error) => {
				if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
			  console.log(error);
			});
	}, [isLoaded]);

	const handleManufacturerChange = (e) =>{
		setManufacturerSelection(e.target.value)
	}

	const handleFromDateChange = (e) =>{
		setFromDate(e.target.value);
	}

	const handleToDateChange = (e) =>{
		setToDate(e.target.value);
	}

	const handleSearchClick = (e) =>{
		console.log(manufacturerSelection+" "+fromDate+" "+toDate+" ")

		let data = new FormData();
		data.append('manufacturer', manufacturerSelection);
		data.append('start', fromDate.replace(/-/g, '/').toString());
		data.append('end', toDate.replace(/-/g, '/').toString());

		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.orders.e-interior.gr/api/tziros',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp', 
		  },
		  data : data
			};

		axios.request(config)
			.then((response) => {
				setStats(response.data);
			})
			.catch((error) => {
			  	console.log(error);
			});
		}

useEffect(() => {
  if (stats && stats.manufacturersSales) {
    setPieData(
      Object.entries(stats.manufacturersSales).map(([name, value]) => ({
        name: name.trim() === "" ? "Χωρίς Κατασκευαστή" : name,
        value: typeof value === "string" ? parseFloat(value.replace('.', '').replace(',', '.')) : value, // Safe conversion
      }))
    );
  }
}, [stats]);



	return(
		<Fragment>
			<div className={classes.container}>
				<div className={classes.filterList}>
					<span className={classes.filterItem}>
						<select value={manufacturerSelection} onChange={handleManufacturerChange}>
							<option value="all">Manufacturers: All</option>
		              	{Array.isArray(manufacturers) && manufacturers.map((option) => (

		                	<option value={option}>{option}</option>

		              	))}
              			</select>
					</span>
					<span className={classes.filterItem}>Από:<input onChange={handleFromDateChange} value={fromDate} type='date'/></span>
					<span className={classes.filterItem}>Εώς:<input onChange={handleToDateChange} value={toDate} type='date'/></span>
					<span className={`${classes.filterItem} ${classes.search}`} onClick={handleSearchClick}>Αναζήτηση</span>
				</div>
				{stats&&
				<Fragment>
					<div className={classes.boxGrid}>
						<div className={classes.saleBoxes}>
							<div className={classes.partialBox}>
								<p className={classes.boxTitle}>Ηλεκτρονικό Κατάστημα</p>
								<div className={classes.partialTotals}>
									<div>
										<p>Σύνολο (χωρίς ΦΠΑ): {stats.eshopStats.total_without_tax}€</p>
										<p><strong>Σύνολο: {stats.eshopStats.total_paid}€</strong></p>
									</div>
									<div className={classes.storePercentage}>
										<p><span>{stats.eshopStats.percentage+'%'}</span></p>
									</div>
								</div>
							</div>
							<div className={classes.partialBox}>
								<p className={classes.boxTitle}>Φυσικό Κατάστημα</p>
								<div className={classes.partialTotals}>
									<div>
										<p>Σύνολο (χωρίς ΦΠΑ): {stats.storeStats.total_without_tax}€</p>
										<p><strong>Σύνολο: {stats.storeStats.total_paid}€</strong></p>
									</div>
									<div className={classes.storePercentage}>
										<p><span>{stats.storeStats.percentage+'%'}</span></p>
									</div>
								</div>
							</div>
							<div className={classes.partialBox}>
								<p className={classes.boxTitle}>Τοποθέτηση</p>
								<div className={classes.partialTotals}>
									<div>
										<p>Συνολικό κόστος τοποθέτησης: {stats.installationStats.total_installation}€</p>
										<p>Συνολικό κόστος βοηθού: {stats.installationStats.total_assistant}€</p>
										<p><strong>Κέρδος: {stats.installationStats.profit}€</strong></p>
									</div>
								</div>
							</div>
						</div>
						<div className={classes.totalBox}>
							<p className={classes.boxTitle}>Σύνολο Καταστημάτων</p>
							<div className={classes.total}>
								<p>Σύνολο :</p>
								<p><strong>{stats.totalStats.total_paid} €</strong></p>
							</div>
						</div>
						<div className={classes.manufacturerBox}>
							<p className={classes.boxTitle}>Πωλήσεις Ανα εταιρεία</p>
							<div className={classes.manufacturersList}>
							
							{Object.entries(stats.manufacturersSales).map(([key, value]) => (
						        <div key={key} className={classes.manufacturersListItem}>
						          <p>{key?key:"Χωρίς Κατασκευαστή"}:</p>
						          <p>{value} €</p>
						        </div>
						      ))}

							</div>
						</div>
					</div>
					{manufacturerSelection=="all"&&<div className={classes.pieChartContainer}> 
						{renderMultiplePieCharts()}
						{renderEverythingElsePieChart()}
						{renderComparisonPieChart()}
					</div>}
				</Fragment>
			}
			</div>
		</Fragment>
		)
}
export default Turnover;