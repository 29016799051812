import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faCheck, faStar} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import isLoggedIn from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import Input from '../UI/Input';
import classes from './SingleAtTailor.module.css'
import axios from 'axios';

//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import CustomProductView from '../ViewProducts/CustomProductView'
import DiadromosView from '../ViewProducts/DiadromosView'

const view = <FontAwesomeIcon icon={faEye} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const check = <FontAwesomeIcon icon={faCheck} />
const star = <FontAwesomeIcon icon={faStar}/>

const SingleAtTailor = (props) =>{
  const [ready,setReady] = useState(0);

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderDate, setOrderDate] = useState('');
  const [viewModal, setViewModal]=useState('');
  const [modalShow, setModalShow] = useState(false);
  const [productStatus, setProductStatus]=useState('');
  const [status,setStatus] = useState(props.status);
  const [statusChange, setStatusChange]=useState("");
  const [dayDiff, setDayDiff] = useState(0)
  const [order,setOrder]=useState('');
  const [orderExtras,setOrderExtras]=useState({paradosi:"",metrisi:"",topothetisi:"",rafis:"",sideroma:""});
  const [orderProducts, setOrderProducts]=useState([]);
  const [orderPayment,setOrderPayment]=useState({paymentType:"",metrita:"",karta:"",synolo:"",prokatavoli:""});
  const [orderDetailed, setOrderDetailed]=useState([{}])
  const [updated, setUpdated]=useState(false);
  const [updateCount, setUpdateCount]=useState(0);
  const [editSewingClick, setEditSewingClick]=useState(false);
  const [editShippingClick, setEditShippingClick]=useState(false);
  const [sewingPrice, setSewingPrice]=useState(JSON.parse(props.expenses).sewing?JSON.parse(props.expenses).sewing:0);
  const [shippingPrice, setShippingPrice]=useState(JSON.parse(props.expenses).shipping?JSON.parse(props.expenses).shipping:0);
  const [editPlaceClick, setEditPlaceClick] = useState(false);
  const [tailorName, setTailorName] = useState(["ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ","ΑΡΗΣ ΣΙΟΥΤΗΣ","ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ","ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ"].includes(props.tailorName)?props.tailorName:(props.tailorName===null)?'':'ΑΛΛΟ');
  const [unlistedTailor, setUnlistedTailor] = useState(props.tailorName);

  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
    setIsLoaded(true);
    console.log(props.clientName);
  }, []);
   


  useEffect(()=>{
    props.onOrdered();
  },[statusChange])


  useEffect(()=>{
      const createdDate = new Date(props.status_updated_at);
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - createdDate.getTime();
      setDayDiff(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));

      const config = {
        headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };

      axios.post('https://api.orders.e-interior.gr/api/order/'+props.order_id,{
        key: cookies.Token},
        config
      )
      .then(function (response) {
        setOrder(response.data)
        console.log(response.data)
        setOrderExtras(JSON.parse(response.data.extras));
        setOrderProducts(response.data.products)
        setOrderPayment(JSON.parse(response.data.payment));
        let details = JSON.parse(response.data.payment).detailed;
        setOrderDetailed(JSON.parse(details));
      })
      .catch(function (error) {
        console.log(error);
      })
  },[updated])

  
  useEffect(()=>{
  if (updateCount>0 && order.products){
        let prep = 0;
        let rdy = 0;
        let sent = 0;
        for (let product of order.products){

          if ((product.status === "ordered" || product.status === "rafeio" || product.status === "ready" ||product.status === "sent" ||product.status === "deleted" || product.status === "merikos"||product.status === "repair" )&&(!(order.status==="ypoloipo"||order.status==="communicated"||order.status==="completed"))){
            prep++;
          }
          if ((product.status === "ready"||product.status === "sent"||product.status === "deleted")&&(!(order.status==="ypoloipo"||order.status==="communicated"||order.status==="completed"))){
            rdy++;
          }

          }
          if (rdy===order.products.length){

                const config = {
                headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
                };
              axios.post('https://api.orders.e-interior.gr/api/order/update',{
                key: cookies.Token,
                order_id: props.order_id,
                status: "ready"
                },
                config)
              .then(function (response) {
                props.onOrdered(); 
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
            
          }else if (prep===order.products.length){

              const config = {
              headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
              };
            axios.post('https://api.orders.e-interior.gr/api/order/update',{
              key: cookies.Token,
              order_id: props.order_id,
              status: "preparing"
              },
              config)
            .then(function (response) {
              props.onOrdered(); 
            })
            .catch(function (error) {
              console.log(error);
            });
            
            }
        props.onOrdered(); 
        }
        setUpdateCount(updateCount+1); 
  },[order])


  
  const handleViewOrderClick = (id) =>{
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status:"ordered"
        },
        config)
      .then(function (response) {
        setStatusChange(response.data)
        
      })
      .catch(function (error) {
        console.log(error);
      });
      setViewModal('');
  }

    const viewClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("roller")){
      setViewModal(<RollerView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")){
      setViewModal(<XaliView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanView id={id} client_name = {props.clientName} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/> )
    }else if (productType.includes('Διάδρομος')){
      setViewModal(<DiadromosView id={id} client_name = {props.clientName} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }
  }
  const viewCloseHandler = ()=>{
    setViewModal('');
  }
  const handleEditSewingClick = ()=>{
    setEditSewingClick(true);
  }
  const handleEditShippingClick = ()=>{
    setEditShippingClick(true);
  }
  const handleEditPlaceClick = ()=>{
    setEditPlaceClick(true);
  }
  const handleSewingSaveClick = ()=>{
    const expensesStr = JSON.stringify({"wholesale":JSON.parse(props.expenses).wholesale,"shipping":shippingPrice,"assistant":JSON.parse(props.expenses).assistant,"sewing":sewingPrice})

    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        expenses:expensesStr
        },
        config)
      .then(function (response) {
        console.log(response);
        setEditSewingClick(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }
    const handleShippingSaveClick = ()=>{
    const expensesStr = JSON.stringify({"wholesale":JSON.parse(props.expenses).wholesale,"shipping":shippingPrice,"assistant":JSON.parse(props.expenses).assistant,"sewing":sewingPrice})
    console.log(expensesStr)
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        expenses:expensesStr
        },
        config)
      .then(function (response) {
        console.log(response);
        setEditShippingClick(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }
  const sewingPriceChangeHandler =(event)=>{
    setSewingPrice(event.target.value);
  }
  const shippingPriceChangeHandler =(event)=>{
    setShippingPrice(event.target.value);
  }
  const handleChange = (event) =>{
    setOrderDate(event.target.value);
  }
  const handleProductStatusChange = (event) =>{
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        status:event.target.value
        },
        config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleViewClick = () =>{
    setModalShow(true);
    props.modalShow({modal:true, id:props.order_id,fullName:props.clientName, status:props.status, scrollPos:window.scrollY});
    setStatus(props.status)
  }
  const handleCheckClick = (event) =>{
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        status:"ready",
        ordered_at: new Date().toISOString().slice(0,19).replace("T"," ")
        },
        config)
      .then(function (response) {
        setUpdated(true);
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const rafeioNoteChangeHandler = (event) =>{
    setTailorName(event.target.value);
  }
  const unlistedTailorChangeHandler = (event) =>{
    setUnlistedTailor(event.target.value);
  }
  const handleRafeioSave = (rafeio, id, unlistedTailor) =>{
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
        };

    console.log (new Date().toISOString());
    if (rafeio==="ΑΛΛΟ"){
      rafeio=unlistedTailor;
    }
    axios.post('https://api.orders.e-interior.gr/api/updateProductStatus',{
          key: cookies.Token,
          note: rafeio,
          status_id:id,
          status_updated_at: new Date().toISOString(),
          },
          config)
        .then(function (response) {
          console.log(response.data);
          setEditPlaceClick(false);
          props.onOrdered(); 
        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
          console.log(error);
        });

 }
    return(

      <Fragment>
      {viewModal}
        <tr className={`${classes.products} ${dayDiff>=7&&dayDiff<10 ? classes.seven:''} ${dayDiff>=10 ? classes.ten:''}`}>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.order_id}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.vip === 1 &&(<span>{star}</span>)}{props.clientName}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.code} - {props.product_type}{props.quantity&&" x "+props.quantity}</td>
          {!editSewingClick &&<td >{sewingPrice>0 ? sewingPrice+'€':' '} <span onClick={handleEditSewingClick}>{pencil}</span></td>}
          {editSewingClick &&<td >
          <input
            className = {classes.sewingPriceInput}
            onChange={sewingPriceChangeHandler}
            type= 'text'
            value= {sewingPrice}
            />
          <span onClick={handleSewingSaveClick}>{save}</span></td>}
          {!editShippingClick &&<td >{shippingPrice>0 ? shippingPrice+'€':' '} <span onClick={handleEditShippingClick}>{pencil}</span></td>}
          {editShippingClick &&<td >
          <input
            className = {classes.sewingPriceInput}
            onChange={shippingPriceChangeHandler}
            type= 'text'
            value= {shippingPrice}
            />
          <span onClick={handleShippingSaveClick}>{save}</span></td>}
          <td>
          {!editPlaceClick&&
            <div className={classes.rafeioShow}>
              <p>{tailorName==='ΑΛΛΟ'?unlistedTailor:(tailorName===''?'---':tailorName)}<span onClick={handleEditPlaceClick}>{pencil}</span></p>
            </div>}
          {editPlaceClick&&<div className={classes.rafeioDiv}>
                      <select className={classes.tailorSelect}  selected={tailorName}  value={tailorName} onChange={(event)=>rafeioNoteChangeHandler(event)}>
                        <option value="0"></option>
                        <option value="ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ">ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ</option>
                        <option value="ΑΡΗΣ ΣΙΟΥΤΗΣ">ΑΡΗΣ ΣΙΟΥΤΗΣ</option>
                        <option value="ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ">ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ</option>
                        <option value="ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ">ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ</option>
                        <option value="ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ">ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ</option>
                        <option value="ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ">ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ</option>
                        <option value="ΑΛΛΟ">ΆΛΛΟ</option>
                      </select>
                      <p className={classes.rafeioSaveBtn} onClick={()=>handleRafeioSave(tailorName,props.statusId,unlistedTailor)}>{save}</p>
                      {tailorName === "ΑΛΛΟ" &&
                      <Input
                      className={classes.rafeioInput}
                      onChange={(event)=>unlistedTailorChangeHandler(event)}
                      label="Ραφείο:"
                      input={{
                        id: 1,
                        type: 'text',
                        value: unlistedTailor
                      }} /> 
                      }
            </div>}
            </td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.status_updated_at}</td>
          <td onClick={handleViewClick}>{view}</td>
          <td onClick={handleCheckClick}>{check}</td>
        </tr>

      </Fragment>)
  }
  
  
  

export default SingleAtTailor;
