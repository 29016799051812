import React, {useState,useEffect} from 'react';
import Card from '../UI/Card';
import classes from './ForWarehouse.module.css'
import SingleForWarehouse from './SingleForWarehouse'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const ForWarehouse = () =>{

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [products,setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [companies, setCompanies] = useState('');
  const [currentCompanies, setCurrentCompanies] = useState('');
  const [companySelection, setCompanySelection] = useState("0");
  const [showOrdered, setShowOrdered] = useState(false)
   

useEffect(() => {
    setIsLoaded(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);

  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies.sort());

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

    axios.post('https://api.orders.e-interior.gr/api/orderedWarehouseProducts/'+showOrdered,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProducts(response.data.sort(function(a,b) {
        if (a.created_at < b.created_at){
          return -1;
        }else if(a.created_at > b.created_at){
          return 1;
        }else{
          return 0;
        }
      }))
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  },[isLoaded, productUpdate, showOrdered])

  useEffect(()=>{
    setCurrentCompanies([...new Set(products.map(item => item.manufacturer))]);
  },[products])
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const handleOrdered = ()=>{
    setProductUpdate(!productUpdate);
  }

  const handleShowOrderedChange = ()=>{
    setShowOrdered(!showOrdered);
  }

  return(
    <Card className={classes.ordersCard}>
    <h2>Εργασίες Αποθήκη</h2>
    <div className={classes.switchContainer}>
      <label className={classes.switch}>
        <input type="checkbox" checked={showOrdered} onChange={handleShowOrderedChange} />
        <span className={classes.slider} />
      </label>
      <p>Εμφάνισε Εκκρεμότητες</p>
    </div>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Προϊόν</th>
          <th>Κωδικός/Περιγραφή</th>
          <th>Σημειώσεις</th>
          <th>Πελάτης</th>
          <th>Ημ. Παραγγελίας</th>
          <th><select id="type" name="type" onChange={companySelectionHandler} value={companySelection}>
            <option value="0"> --- Εταιρεία --- </option>
            {currentCompanies && currentCompanies.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {products.map(({id,order_id,code,product_type,manufacturer,ordered_at,city,clientName, vip, status, product_options, order})=>{
        if ((companySelection==="0"||manufacturer===companySelection)&&(order.status!=="not_submitted_yet")&&(order.status!=="proxeiro")&&(order.status!=="deleted")){
        return(
          <SingleForWarehouse

          id={id}
          order_id={order_id}
          key={id}
          code={code}
          status={status}
          product={product_type}
          quantity={JSON.parse(product_options).quantity}
          city={city}
          client={clientName}
          vip={vip}
          ordered_at={ordered_at}
          options={product_options}
          manufacturer={manufacturer}
          onOrdered={handleOrdered}/>
          )}
        }
      )}
      </tbody>
    </table>
    </Card>
  )
}
export default ForWarehouse;
