import React,{Fragment,useState,useEffect} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import Button from '../UI/Button';
import Card from '../UI/Card'
import classes from './MainButtons.module.css';
import ViewOrderInProgress from '../OrdersInProgress/ViewOrderInProgress'
import CancelOrder from '../OrdersInProgress/CancelOrder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faComment, faCheck, faTrash, faBook} from '@fortawesome/free-solid-svg-icons'
import UserLogin from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import axios from 'axios';

const MainButtons = (props) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [count, setCount] = useState({0: '0'});
  const [lateForSewing, setLateForSewing] = useState([]);
  const [nameSelection, setNameSelection] = useState('0')
  const [currentClients, setCurrentClients] = useState('')
  const [statusText,setStatusText] = useState("");
  const [orderRender, setOrderRender] = useState("");
  const [modalShow,setModalShow] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const [currentId,setCurrentId] =useState('');
  const [currentFullName,setCurrentFullName] =useState('');

  const pencil = <FontAwesomeIcon icon={faPencilAlt} />
  const save = <FontAwesomeIcon icon={faSave} />
  const view = <FontAwesomeIcon icon={faEye} />
  const communicate = <FontAwesomeIcon icon={faComment}/>
  const check = <FontAwesomeIcon icon={faCheck} />
  const trash = <FontAwesomeIcon icon={faTrash} />
  const history = <FontAwesomeIcon icon={faBook} />

useEffect(() => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });
}, []);

  const nameSelectionHandler = (e) =>{
    setNameSelection(e.target.value);
  } 

  const handleViewClick = (id,name) =>{
    setModalShow(true);
    setCurrentId(id)
    setCurrentFullName(name)
    setStatusChange(!statusChange);
  }

  const handleCancelClick = (id) =>{
    setCancelModal(true);
    setCurrentId(id);
  }
  const handleCancelYes = (e) =>{
    setCancelModal(false);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: currentId,
      status: "canceled"
      },
      config)
    .then(function (response) {
      setStatusChange(!statusChange);
      setCurrentId('');
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }
  const handleCancelNo = (e) =>{
    setCancelModal(false);
    setCurrentId('');
  }
  const onCloseHandler = () =>{
    setModalShow(false);
    setCurrentId('');
    setCurrentFullName('');
    setStatusChange(!statusChange);
  }
  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
  });

  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setCount(response.data);
      setLateForSewing(response.data[0].lateForSewing)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  },[])
  

  return (
    <Fragment>
    {cancelModal && <CancelOrder id={currentId} yesClick={handleCancelYes} noClick={handleCancelNo}/>}
    {modalShow && 
      <Card className={classes.orderCard}><ViewOrderInProgress id={currentId} fullName={currentFullName} onClose={onCloseHandler} /></Card>}
    {!modalShow &&
    <Fragment>
    <div className={`${classes.grid} ${classes.firstGrid}`}>
    <span className={`${classes.firstButton}`}><Link to="new-order/0"> <Button>+Νέα Παραγγελία</Button></Link></span>
    <span className={`${classes.secondButton}`}><Link to="in-progress"> <Button>Σε εξέλιξη</Button></Link><span className={`${classes.badge} ${classes.greenBadge} ${classes.active}`}>{count[0]['totalOrdersReady']>0?count[0]['totalOrdersReady']:0}</span></span>
    <span className={`${classes.thirdButton}`}><Link to="order-history"><Button>Ιστορικό Παραγγελιών</Button></Link></span>
    </div>
    <div className={classes.grid}>
    <span className={`${classes.secondaryFirstButton}`}><Link to="installations"><Button><span>Τοποθετήσεις</span></Button></Link>
      <span className={`${classes.badge} ${classes.new} ${classes.active}`}>{count[0]['totalOrdersForInstallation']>0?count[0]['totalOrdersForInstallation']:0}</span>
      <span className={`${classes.badge} ${classes.seven} ${count[0]['totalOrdersForInstallationSeven']>0 && classes.active}`}>{count[0]['totalOrdersForInstallationSeven']>0?count[0]['totalOrdersForInstallationSeven']:0}</span>
      <span className={`${classes.badge} ${classes.ten} ${count[0]['totalOrdersForInstallationTen']>0&& classes.active}`}>{count[0]['totalOrdersForInstallationTen']>0?count[0]['totalOrdersForInstallationTen']:0}</span>
    </span>
    <span className={`${classes.secondarySecondButton}`}><Link to="to-order"><Button>Για Παραγγελία</Button></Link>
      <span className={`${classes.badge} ${classes.new} ${classes.active}`}>{count[0]['totalProductsForOrdering']>0?count[0]['totalProductsForOrdering']:0}</span>
      <span className={`${classes.badge} ${classes.seven} ${count[0]['totalProductsForOrderingSeven']>0&& classes.active}`}>{count[0]['totalProductsForOrderingSeven']>0?count[0]['totalProductsForOrderingSeven']:0}</span>
      <span className={`${classes.badge} ${classes.ten} ${count[0]['totalProductsForOrderingTen']>0&& classes.active}`}>{count[0]['totalProductsForOrderingTen']>0?count[0]['totalProductsForOrderingTen']:0}</span>
    </span>
    <span className={`${classes.secondaryThirdButton}`}>{<Link to="deficiencies"><Button>Ελλείψεις</Button></Link>}</span>
    <span className={`${classes.secondaryFourthButton}`}><Link to="at-tailor"><Button>Στο Ραφείο</Button></Link>
      <span className={`${classes.badge} ${classes.new} ${classes.active}`}>{count[0]['totalProductsForRafeio']>0?count[0]['totalProductsForRafeio']:0}</span>
      <span className={`${classes.badge} ${classes.seven} ${count[0]['totalProductsForRafeioSeven']>0&& classes.active}`}>{count[0]['totalProductsForRafeioSeven']>0?count[0]['totalProductsForRafeioSeven']:0}</span>
      <span className={`${classes.badge} ${classes.ten} ${count[0]['totalProductsForRafeioTen']>0&& classes.active}`}>{count[0]['totalProductsForRafeioTen']?count[0]['totalProductsForRafeioTen']:0}</span>
    </span>
    <span className={`${classes.secondaryFifthButton}`}><Link to="not-submitted"><Button>Πρόχειρο</Button></Link></span>
    <span className={`${classes.secondarySixthButton}`}><Link to="for-warehouse"><Button>Εργασίες Αποθήκη</Button></Link><span className={`${classes.badge} ${classes.new} ${classes.active}`}>{count[0]['totalProductsForWarehouse']>0?count[0]['totalProductsForWarehouse']:0}</span></span>
    </div>
    <Outlet/>
    {lateForSewing.length>0 &&
    <Card className={classes.lateForSewing}>
    {lateForSewing.map((result)=>{
      return(
        <p className={classes.lateList} onClick={()=>handleViewClick(result.id,result.fullName)}>Ο/Η {result.fullName} με ID {result.id} έχει ύφασμα που καθυστέρησε πανω από 2 μέρες.</p>
        )}
      )
    }
    </Card>
    }
    </Fragment>}
    </Fragment>
  )
};
export default MainButtons;
